<template>
  <div class="container">
    <div class="header" v-show="isAdmin">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="单位名称">
          <el-select v-model="searchForm.unitId" placeholder="请选择所在单位" clearable filterable remote :remote-method="remoteMethod" size="small">
            <el-option v-for="(item,i) in unitOptions" :key="i" :label="item.unitName" :value="item.id">
              <span style="float: left">{{ item.unitName }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px;margin-left:3px;">{{ item.address }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="initTable">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="correctList" stripe style="width: 100%" height="100%">
        <el-table-column prop="unitName" label="单位名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="createTime" label="通知发布时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.createTime|timeFormat}}
          </template>
        </el-table-column>
        <el-table-column label="操作" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tooltip content="查看" placement="top" effect="dark">
              <el-button type="primary" circle icon="el-icon-view" size="small" @click="open(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <el-dialog title="责令整改通知" :visible.sync="correctShow" width="50%">
      <table class="unit-attr">
        <tr>
          <td colspan="12">根据《中华人民共和国消防法》第五十三条规定，我所于_对你单位（场所）进行消防监督检查，发现你单位（场所）存 在下列第_项消防安全违法行为，现责令改正：</td>
        </tr>
        <tr>
          <td colspan="12">
            1.<el-radio :disabled="true" v-model="correct.option1" :label="0">未制定消防安全制度</el-radio>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            2.<el-radio-group v-model="correct.option2">
              <el-radio :disabled="true" v-for="(item,i) in [{id:0,label:'未组织'},{id:1,label:'防火检查'},{id:2,label:'消防安全教育培训'},{id:3,label:'消防演练'}]" :key="i" :label="item.id">
                {{item.label}}
              </el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            3.<el-radio-group v-model="correct.option3">
              <el-radio :disabled="true" v-for="(item,i) in [{id:0,label:'占用'},{id:1,label:'堵塞'},{id:2,label:'封闭疏散通道、安全出口'}]" :key="i" :label="item.id">
                {{item.label}}
              </el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            4.<el-radio-group v-model="correct.option4">
              <el-radio :disabled="true" v-for="(item,i) in [{id:0,label:'占用'},{id:1,label:'堵塞'},{id:2,label:'封闭消防车通道，妨碍消防车通行'}]" :key="i" :label="item.id">
                {{item.label}}
              </el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            5.<el-radio-group v-model="correct.option5">
              <el-radio :disabled="true" v-for="(item,i) in [{id:0,label:'埋压'},{id:1,label:'圈占'},{id:2,label:'遮挡消防栓'},{id:3,label:'占用防火间距'}]" :key="i" :label="item.id">
                {{item.label}}
              </el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            6.<el-radio-group v-model="correct.option6">
              <el-radio :disabled="true" v-for="(item,i) in [{id:0,label:'室内消防栓'},{id:1,label:'灭火器'},{id:2,label:'疏散指示标志'},{id:3,label:'应急照明未保持完好有效'}]" :key="i" :label="item.id">
                {{item.label}}
              </el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            7.<el-radio :disabled="true" v-model="correct.option7" :label="0">人员密集场所外墙门窗上设置影响逃生、灭火救援的障碍物</el-radio>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            8.<el-radio-group v-model="correct.option8">
              <el-radio :disabled="true" v-for="(item,i) in [{id:0,label:'违反消防安全规定进入'},{id:1,label:'生产'},{id:2,label:'储存易燃易爆危险品场所'}]" :key="i" :label="item.id">
                {{item.label}}
              </el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            9.<el-radio :disabled="true" v-model="correct.option9" :label="0">违反规定使用明火作业</el-radio>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            10.<el-radio-group v-model="correct.option10">
              <el-radio :disabled="true" v-for="(item,i) in [{id:0,label:'在具有火灾、爆炸危险的场所'},{id:1,label:'吸烟'},{id:2,label:'使用明火'}]" :key="i" :label="item.id">
                {{item.label}}
              </el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            11.<el-radio-group v-model="correct.option11">
              <el-radio :disabled="true" v-for="(item,i) in [{id:0,label:'生产'},{id:1,label:'储存'},{id:2,label:'经营易燃易爆危险品的场所与居住场所设置在同一建筑物内的；'}]" :key="i" :label="item.id">
                {{item.label}}
              </el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            12.<el-radio :disabled="true" v-model="correct.option12" :label="0">未对建筑消防设施定期进行维修保养</el-radio>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            13.<el-radio :disabled="true" v-model="correct.option13" :label="0">其他消防安全违法行为和火灾隐患</el-radio>
          </td>
        </tr>
        <tr colspan="12" v-if="correct.remark">
          <td colspan="4">备注</td>
          <td colspan="8">
            {{correct.remark}}
          </td>
        </tr>
        <tr>
          <td colspan="6" class="center">被检查单位(场所)签收:</td>
          <td colspan="6" class="center nopadding">
            <el-button type="primary" size="default" @click="preview()" style="width:100%;height:100%;border-radius:0;">预览</el-button>
          </td>
        </tr>
        <tr>
          <td colspan="12">一式两份，一份交被检查单位(场所)，一份存档。</td>
        </tr>
      </table>
    </el-dialog>
    <el-dialog title="电子签名" :visible.sync="signShow" width="50%" class="sign">
      <img :src="correct.headName" alt="" width="100%">
    </el-dialog>

  </div>
</template>

<script>
export default {
  props: {
    companyId: {
      type: Number,
      default: 0
    },
    isAdmin: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      currentPage: 1,
      total: 0,
      correctShow: false,
      signShow: false,
      correctList: [],
      correct: {},
      searchForm: {},
      unitOptions: []
    }
  },
  created () {
    this.initTable()
    this.correctFormCopy = JSON.stringify(this.correctForm)
  },
  methods: {
    async handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    // 初始化表格
    async initTable () {
      const data = {}
      if (this.searchForm.unitId > 0) {
        data.unitId = this.searchForm.unitId
      }
      if (this.companyId > 0) {
        data.unitId = this.companyId
      }
      data.pageNo = this.currentPage
      data.pageSize = 10
      const { data: result } = await this.$axios.get('/rectifyNotice/list', { params: data })
      if (result.code === 200) {
        this.correctList = result.data.result
        this.total = result.data.total
      } else {
        this.$message.error(result.msg)
      }
    },
    async remoteMethod (query) {
      const { data: result } = await this.$axios.get('/unit/listAllUnit', { params: { unitName: query } })
      if (result.code === 200) {
        this.unitOptions = result.data
      }
    },
    open (row) {
      this.correct = row
      this.correctShow = true
    },
    preview () {
      if (!this.correct.headName) {
        this.$message.error('暂无签名')
      } else {
        this.signShow = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.unit-attr {
  width: 100%;
  border: 1px solid #ddd;
  box-sizing: border-box;
  border-collapse: collapse;
  table-layout: fixed;
  word-break: break-all;
  white-space: normal;
  td {
    height: 45px;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
    padding: 5px;
    text-align: left;
    color: #fff;
    .el-radio {
      color: #fff;
      ::v-deep .el-radio__label {
        padding-left: 5px;
      }
      ::v-deep .is-disabled {
        .el-radio__inner::after {
          background-color: #409eff;
          width: 6px;
          height: 6px;
        }
      }
    }
    .el-input-number {
      width: 100%;
      height: 100%;
      ::v-deep .el-input-number__decrease,
      ::v-deep .el-input-number__increase {
        background-color: rgba(7, 104, 159, 0.15);
        color: #fff;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      ::v-deep .el-input {
        height: 100%;
        .el-input__inner {
          height: 100%;
        }
      }
    }
  }
  .nopadding {
    padding: 0;
  }
  .center {
    text-align: center;
  }
}
</style>
